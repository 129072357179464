export const FETCH_TIMESHEET = "FETCH_TIMESHEET";
export const FETCH_TIMESHEET_SUCCESS = "FETCH_TIMESHEET_SUCCESS";
export const FETCH_TIMESHEET_FAILED = "FETCH_TIMESHEET_FAILED";

export const CREATE_TIMESHEET = "CREATE_TIMESHEET";
export const CREATE_TIMESHEET_SUCCESS = "CREATE_TIMESHEET_SUCCESS";
export const CREATE_TIMESHEET_FAILED = "CREATE_TIMESHEET_FAILED";

export const UPDATE_TIMESHEET = "UPDATE_TIMESHEET";
export const UPDATE_TIMESHEET_SUCCESS = "UPDATE_TIMESHEET_SUCCESS";
export const UPDATE_TIMESHEET_FAILED = "UPDATE_TIMESHEET_FAILED";

export const BULK_SUBMIT_TIMESHEET = "BULK_SUBMIT_TIMESHEET";
export const BULK_SUBMIT_TIMESHEET_SUCCESS = "BULK_SUBMIT_TIMESHEET_SUCCESS";
export const BULK_SUBMIT_TIMESHEET_FAILED = "BULK_SUBMIT_TIMESHEET_FAILED";

export const SET_TIMESHEET_ERROR_MESSAGE = "SET_TIMESHEET_ERROR_MESSAGE";

export const DELETE_TIMESHEET = "DELETE_TIMESHEET";
export const DELETE_TIMESHEET_SUCCESS = "DELETE_TIMESHEET_SUCCESS";
export const DELETE_TIMESHEET_FAILED = "DELETE_TIMESHEET_FAILED";

export const EDIT_TIMESHEET_DETAIL = "EDIT_TIMESHEET_DETAIL";
export const EDIT_TIMESHEET_DETAIL_SUCCESS = "EDIT_TIMESHEET_DETAIL_SUCCESS";
export const EDIT_TIMESHEET_DETAIL_FAILED = "EDIT_TIMESHEET_DETAIL_FAILED";
