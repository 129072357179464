import { combineReducers } from "redux";
import SignupReducer from "./signup/reducer";
import ApplicationPolicyReducer from "./applicationpolicy/reducer";
import MappingReducer from "./mapping/reducer";
import DomainBlockingReducer from "./domain-blocking/reducer";
import BreakReducer from "./break/reducer";
import ShiftReducer from "./shift/reducer";
import Wellness360Reducer from "./wellness-summary/reducer";
import DesignationReducer from "./designation/reducer";
import AttendanceSummaryReducer from "./attendance-summary/reducer";
import AttendanceDetailedReducer from "./attendance-detailed/reducer";
import RoleReducer from "./role/reducer";
import UserReducer from "./users/reducer";
import TeamReducer from "./team/reducer";
import TenantReducer from "./organization/reducer";
import ActivitySummaryReducer from "./activity-summary/reducer";
import ActivityDetailReducer from "./activity-detail/reducer";
import ProductivitySummaryReducer from "./productivity-summary/reducer";
import ProductivityDetailReducer from "./productivity-detail/reducer";
import ApplicationSummaryReducer from "./application-summary/reducer";
import ApplicationDetailedReducer from "./application-detail/reducer";
import WorkplaceSettingReducer from "./workplace-settings/reducer";
import Wellness360DetailsReducer from "./wellness-details/reducer";
import ReportsDailyAttendanceReducer from "./reports/dailyAttendance/reducer";
import DynamicReportReducer from "./reports/dynamic-report/reducer";
import ProductivityReportReducer from "./reports/productivity-report/reducer";
import ActivityReportReducer from "./reports/activity-report/reducer";
import ReportMonthlyAttendanceReducer from "./reports/monthly-attendance/reducer";
import TimelineReducer from "./timeline/reducer";
import LivestreamReducer from "./livestream/reducer";
import GroupInsightReportReducer from "./reports/groupinsight-report/reducer";
import ApplicationReportReducer from "./reports/application-report/reducer";
import BreakReportReducer from "./reports/break-report/reducer";
import ReportMonthlyInoutReducer from "./reports/monthlinout/reducer";
import AlertsReducer from "./alerts/reducer";
import RolesReducer from "./roles/reducer";
import ProjectsReducer from "./projects/reducer";
import TaskActivitiesReducer from "./task-activities/reducer";
import TasksReducer from "./tasks/reducer";
import BillingReducer from "./billing/reducer";
import ScreenshotReducer from "./screenshot/reducer";
import TenantSettingsReducer from "./tenant-settings/reducer";
import DownloadReducer from "./download/reducer";
import UserDetailReducer from "./user-detail/reducer";
import NotesReducer from "./notes/reducer";
import FieldReducer from "./field/reducer";
import LeaveTypeReducer from "./leave-type/reducer";
import LeaveReducer from "./leave/reducer";
import UserDeviceReducer from "./user-device/reducer";
import ProjectsReportReducer from "./reports/project-report/reducer";
import ManualTimeReducer from "./manualtime/reducer";
import GoalsReducer from "./goals/reducer";
import FiltersReducer from "./filters/reducer";
import LogsReportReducer from "./reports/logs-report/reducer";
import UserScorecardReducer from "./user-scorecard/reducer";
import GenAIReducer from "./gen-ai/reducer";
import IntegrationsReducer from "./integrations/reducer";
import CrossDayAttendanceReportReducer from "./reports/cross-day-attendance-report/reducer";
import TimesheetReducer from "./timesheet/reducer";

export default combineReducers({
  signup: SignupReducer,
  applicationpolicy: ApplicationPolicyReducer,
  mapping: MappingReducer,
  domainblocking: DomainBlockingReducer,
  break: BreakReducer,
  shift: ShiftReducer,
  wellness360summary: Wellness360Reducer,
  designation: DesignationReducer,
  attendancesummary: AttendanceSummaryReducer,
  attendancedetailed: AttendanceDetailedReducer,
  role: RoleReducer,
  users: UserReducer,
  team: TeamReducer,
  tenant: TenantReducer,
  activitysummary: ActivitySummaryReducer,
  actvitydetail: ActivityDetailReducer,
  productivitysummary: ProductivitySummaryReducer,
  productivitydetail: ProductivityDetailReducer,
  applicationsummary: ApplicationSummaryReducer,
  applicationdetailed: ApplicationDetailedReducer,
  workplaceSettings: WorkplaceSettingReducer,
  ReportMonthlyAttendanceReducer: ReportMonthlyAttendanceReducer,
  wellness360details: Wellness360DetailsReducer,
  reportsdailyattendance: ReportsDailyAttendanceReducer,
  timeline: TimelineReducer,
  livestream: LivestreamReducer,
  applicationreport: ApplicationReportReducer,
  groupinsightreport: GroupInsightReportReducer,
  productivityreport: ProductivityReportReducer,
  activityreport: ActivityReportReducer,
  breakreport: BreakReportReducer,
  ReportMonthlyInoutReducer: ReportMonthlyInoutReducer,
  dynamicReport: DynamicReportReducer,
  alerts: AlertsReducer,
  projects: ProjectsReducer,
  taskActivities: TaskActivitiesReducer,
  tasks: TasksReducer,
  billing: BillingReducer,
  screenshot: ScreenshotReducer,
  tenantSettings: TenantSettingsReducer,
  download: DownloadReducer,
  userDetail: UserDetailReducer,
  notes: NotesReducer,
  field: FieldReducer,
  leaveType: LeaveTypeReducer,
  leave: LeaveReducer,
  userdevice: UserDeviceReducer,
  projectsreport: ProjectsReportReducer,
  manualtime: ManualTimeReducer,
  goals: GoalsReducer,
  roles: RolesReducer,
  filters: FiltersReducer,
  logsreport: LogsReportReducer,
  userscorecard: UserScorecardReducer,
  genAI: GenAIReducer,
  integrations: IntegrationsReducer,
  crossdayattendancereport: CrossDayAttendanceReportReducer,
  timesheet: TimesheetReducer,
});
