import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchTimesheetLoading: true,
  timesheetData: [],
  pagination: initialPagination,
  timesheetLoading: false,
  timesheetSuccess: false,
  deleteTimesheetSuccess: false,
  deleteTimesheetLoading: false,
  timesheetErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_TIMESHEET:
      return {
        ...state,
        fetchTimesheetLoading: true,
        timesheetErrorMessage: "",
        timesheetData: [],
      };

    case actionTypes.FETCH_TIMESHEET_SUCCESS:
      return {
        ...state,
        fetchTimesheetLoading: false,
        timesheetData: action.payload.data,
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_TIMESHEET_FAILED:
      return {
        ...state,
        fetchTimesheetLoading: false,
        timesheetData: [],
        pagination: initialPagination,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.CREATE_TIMESHEET:
      return {
        ...state,
        timesheetLoading: true,
        timesheetErrorMessage: "",
        timesheetSuccess: false,
      };

    case actionTypes.CREATE_TIMESHEET_SUCCESS: {
      return {
        ...state,
        timesheetLoading: false,
        // timesheetData: {
        //   ...state.timesheetData,
        //   [action.payload.id]: action.payload,
        // },
        timesheetData: [...state.timesheetData, action.payload],

        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        timesheetSuccess: true,
      };
    }

    case actionTypes.CREATE_TIMESHEET_FAILED:
      return {
        ...state,
        timesheetLoading: false,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_TIMESHEET:
      return {
        ...state,
        timesheetLoading: true,
        timesheetErrorMessage: "",
        timesheetSuccess: false,
      };

    case actionTypes.UPDATE_TIMESHEET_SUCCESS:
      return {
        ...state,
        timesheetLoading: false,
        timesheetData: {
          ...state.timesheetData,
          [action.payload.id]: action.payload,
        },
        timesheetSuccess: true,
      };

    case actionTypes.UPDATE_TIMESHEET_FAILED:
      return {
        ...state,
        timesheetLoading: false,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.SET_TIMESHEET_ERROR_MESSAGE:
      return {
        ...state,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.DELETE_TIMESHEET:
      return {
        ...state,
        deleteTimesheetLoading: true,
        timesheetErrorMessage: "",
        timesheetSuccess: false,
      };

    case actionTypes.DELETE_TIMESHEET_SUCCESS:
      return {
        ...state,
        deleteTimesheetLoading: false,
        timesheetSuccess: true,
      };

    case actionTypes.DELETE_TIMESHEET_FAILED:
      return {
        ...state,
        deleteTimesheetLoading: false,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.BULK_SUBMIT_TIMESHEET:
      return {
        ...state,
        timesheetLoading: true,
        timesheetErrorMessage: "",
        timesheetSuccess: false,
      };

    case actionTypes.BULK_SUBMIT_TIMESHEET_SUCCESS:
      return {
        ...state,
        timesheetLoading: false,
        timesheetSuccess: true,
      };

    case actionTypes.BULK_SUBMIT_TIMESHEET_FAILED:
      return {
        ...state,
        timesheetLoading: false,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.EDIT_TIMESHEET_DETAIL:
      return {
        ...state,
        timesheetLoading: true,
        timesheetErrorMessage: "",
        timesheetSuccess: false,
      };

    case actionTypes.EDIT_TIMESHEET_DETAIL_SUCCESS: {
      const fetchlist = [...state.timesheetData];
      const findIndex = fetchlist.findIndex(
        (item) => item.id == action.payload.id
      );
      fetchlist[findIndex] = action.payload;
      return {
        ...state,
        timesheetLoading: false,
        timesheetData: fetchlist,
        // projectsData: {
        //   ...state.projectsData,
        //   [action.payload.id]: action.payload,
        // },
        timesheetSuccess: true,
      };
    }

    case actionTypes.EDIT_TIMESHEET_DETAIL_FAILED:
      return {
        ...state,
        timesheetLoading: false,
        timesheetErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
