import dayjs from "dayjs";
import WellnessIcon from "assets/images/wellness_icon.svg";
import ShiftIcon from "assets/images/shifts_icon.svg";
import UserDesignationIcon from "assets/images/users_designation.svg";
import BreaksIcon from "assets/images/breaks_icon.svg";
import ProductivityRulesIcon from "assets/images/productivity_rule.svg";
import TeamsIcon from "assets/images/teams_icon.svg";
import EmailReportsIcon from "assets/images/email_reports.svg";
import AlertRulesIcon from "assets/images/alert_rules.svg";
import Users from "assets/images/userIO.svg";
import Attendance from "assets/images/module-search/attendance.svg";
import Dashboard from "assets/images/module-search/dashboard.svg";
import Wellness from "assets/images/module-search/wellness.svg";
import Livestream from "assets/images/module-search/live-stream.svg";
import Activity from "assets/images/module-search/activity.svg";
import Productivity from "assets/images/module-search/productivity.svg";
import Timeline from "assets/images/module-search/timeline.svg";
import Application from "assets/images/module-search/applications.svg";
import Reports from "assets/images/module-search/reports.svg";
import Project from "assets/images/module-search/projects.svg";
import Alerts from "assets/images/module-search/alerts.svg";
import Fields from "assets/images/module-search/Field.svg";
import Screenshot from "assets/images/module-search/screenshots.svg";
import Note from "assets/images/module-search/notes.svg";
import Billing from "assets/images/module-search/Billing.svg";
import RaiseTicet from "assets/images/module-search/Raise a ticket.svg";
import Download from "assets/images/module-search/Download.svg";
import RequestOnboarding from "assets/images/module-search/Request onboarding.svg";
import Documentation from "assets/images/module-search/documentation.svg";
import Settings from "assets/images/module-search/setting.svg";
import Device360 from "assets/images/module-search/device-360.svg";
import GoalsIcon from "assets/images/module-search/Goals.svg";
import { raiseTicketURL, requestOnboardingURL } from "utils/transport";
import type { TimeRangePickerProps } from "antd";

export const ALERT_TYPE_LABEL: Record<AlertType, string> = {
  APP_NAME_SINGLE_INSTANCE: "Application",
  APP_URL_SINGLE_INSTANCE: "URL",
  OVERTIME_BREAK: "Overtime Break",
  INACTIVITY: "Inactivity",
};

export const rangePresets: TimeRangePickerProps["presets"] = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

export const allrangePresets: TimeRangePickerProps["presets"] = [
  {
    label: "Today",
    value: [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 31 Days",
    value: [dayjs().add(-31, "d"), dayjs()],
  },
];

export const timeZoneList = [
  { country: "ASIA", timeZone: "Asia/Aden", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Cuiaba", utcOffset: "UTC -04:00" },
  { country: "ETC", timeZone: "Etc/GMT+9", utcOffset: "UTC -09:00" },
  { country: "ETC", timeZone: "Etc/GMT+8", utcOffset: "UTC -08:00" },
  { country: "AFRICA", timeZone: "Africa/Nairobi", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Marigot", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Aqtau", utcOffset: "UTC +05:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Kwajalein",
    utcOffset: "UTC +12:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/El_Salvador",
    utcOffset: "UTC -06:00",
  },
  { country: "ASIA", timeZone: "Asia/Pontianak", utcOffset: "UTC +07:00" },
  { country: "AFRICA", timeZone: "Africa/Cairo", utcOffset: "UTC +03:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Pago_Pago",
    utcOffset: "UTC -11:00",
  },
  { country: "AFRICA", timeZone: "Africa/Mbabane", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Kuching", utcOffset: "UTC +08:00" },
  { country: "PACIFIC", timeZone: "Pacific/Honolulu", utcOffset: "UTC -10:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Rarotonga",
    utcOffset: "UTC -10:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Guatemala",
    utcOffset: "UTC -06:00",
  },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Hobart",
    utcOffset: "UTC +10:00",
  },
  { country: "EUROPE", timeZone: "Europe/London", utcOffset: "UTC +01:00" },
  { country: "AMERICA", timeZone: "America/Belize", utcOffset: "UTC -06:00" },
  { country: "AMERICA", timeZone: "America/Panama", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Chungking", utcOffset: "UTC +08:00" },
  { country: "AMERICA", timeZone: "America/Managua", utcOffset: "UTC -06:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Petersburg",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Yerevan", utcOffset: "UTC +04:00" },
  { country: "EUROPE", timeZone: "Europe/Brussels", utcOffset: "UTC +02:00" },
  { country: "Unknown", timeZone: "GMT", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Warsaw", utcOffset: "UTC +02:00" },
  { country: "AMERICA", timeZone: "America/Chicago", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Kashgar", utcOffset: "UTC +06:00" },
  { country: "CHILE", timeZone: "Chile/Continental", utcOffset: "UTC -04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Yap", utcOffset: "UTC +10:00" },
  { country: "Unknown", timeZone: "CET", utcOffset: "UTC +02:00" },
  { country: "ETC", timeZone: "Etc/GMT-1", utcOffset: "UTC +01:00" },
  { country: "ETC", timeZone: "Etc/GMT-0", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Jersey", utcOffset: "UTC +01:00" },
  {
    country: "AMERICA",
    timeZone: "America/Tegucigalpa",
    utcOffset: "UTC -06:00",
  },
  { country: "ETC", timeZone: "Etc/GMT-5", utcOffset: "UTC +05:00" },
  { country: "EUROPE", timeZone: "Europe/Istanbul", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Eirunepe", utcOffset: "UTC -05:00" },
  { country: "ETC", timeZone: "Etc/GMT-4", utcOffset: "UTC +04:00" },
  { country: "AMERICA", timeZone: "America/Miquelon", utcOffset: "UTC -02:00" },
  { country: "ETC", timeZone: "Etc/GMT-3", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Luxembourg", utcOffset: "UTC +02:00" },
  { country: "ETC", timeZone: "Etc/GMT-2", utcOffset: "UTC +02:00" },
  { country: "ETC", timeZone: "Etc/GMT-9", utcOffset: "UTC +09:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Catamarca",
    utcOffset: "UTC -03:00",
  },
  { country: "ETC", timeZone: "Etc/GMT-8", utcOffset: "UTC +08:00" },
  { country: "ETC", timeZone: "Etc/GMT-7", utcOffset: "UTC +07:00" },
  { country: "ETC", timeZone: "Etc/GMT-6", utcOffset: "UTC +06:00" },
  { country: "EUROPE", timeZone: "Europe/Zaporozhye", utcOffset: "UTC +03:00" },
  { country: "CANADA", timeZone: "Canada/Yukon", utcOffset: "UTC -07:00" },
  { country: "CANADA", timeZone: "Canada/Atlantic", utcOffset: "UTC -03:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/St_Helena",
    utcOffset: "UTC +00:00",
  },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Tasmania",
    utcOffset: "UTC +10:00",
  },
  { country: "Unknown", timeZone: "Libya", utcOffset: "UTC +02:00" },
  { country: "EUROPE", timeZone: "Europe/Guernsey", utcOffset: "UTC +01:00" },
  {
    country: "AMERICA",
    timeZone: "America/Grand_Turk",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Samarkand", utcOffset: "UTC +05:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Cordoba",
    utcOffset: "UTC -03:00",
  },
  { country: "ASIA", timeZone: "Asia/Phnom_Penh", utcOffset: "UTC +07:00" },
  { country: "AFRICA", timeZone: "Africa/Kigali", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Almaty", utcOffset: "UTC +05:00" },
  { country: "United States", timeZone: "US/Alaska", utcOffset: "UTC -08:00" },
  { country: "ASIA", timeZone: "Asia/Dubai", utcOffset: "UTC +04:00" },
  {
    country: "EUROPE",
    timeZone: "Europe/Isle_of_Man",
    utcOffset: "UTC +01:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Araguaina",
    utcOffset: "UTC -03:00",
  },
  { country: "Unknown", timeZone: "Cuba", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Novosibirsk", utcOffset: "UTC +07:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Salta",
    utcOffset: "UTC -03:00",
  },
  { country: "ETC", timeZone: "Etc/GMT+3", utcOffset: "UTC -03:00" },
  { country: "AFRICA", timeZone: "Africa/Tunis", utcOffset: "UTC +01:00" },
  { country: "ETC", timeZone: "Etc/GMT+2", utcOffset: "UTC -02:00" },
  { country: "ETC", timeZone: "Etc/GMT+1", utcOffset: "UTC -01:00" },
  { country: "PACIFIC", timeZone: "Pacific/Fakaofo", utcOffset: "UTC +13:00" },
  { country: "AFRICA", timeZone: "Africa/Tripoli", utcOffset: "UTC +02:00" },
  { country: "ETC", timeZone: "Etc/GMT+0", utcOffset: "UTC +00:00" },
  { country: "Unknown", timeZone: "Israel", utcOffset: "UTC +03:00" },
  { country: "AFRICA", timeZone: "Africa/Banjul", utcOffset: "UTC +00:00" },
  { country: "ETC", timeZone: "Etc/GMT+7", utcOffset: "UTC -07:00" },
  { country: "INDIAN", timeZone: "Indian/Comoro", utcOffset: "UTC +03:00" },
  { country: "ETC", timeZone: "Etc/GMT+6", utcOffset: "UTC -06:00" },
  { country: "ETC", timeZone: "Etc/GMT+5", utcOffset: "UTC -05:00" },
  { country: "ETC", timeZone: "Etc/GMT+4", utcOffset: "UTC -04:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Port_Moresby",
    utcOffset: "UTC +10:00",
  },
  { country: "United States", timeZone: "US/Arizona", utcOffset: "UTC -07:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Syowa",
    utcOffset: "UTC +03:00",
  },
  { country: "INDIAN", timeZone: "Indian/Reunion", utcOffset: "UTC +04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Palau", utcOffset: "UTC +09:00" },
  {
    country: "EUROPE",
    timeZone: "Europe/Kaliningrad",
    utcOffset: "UTC +02:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Montevideo",
    utcOffset: "UTC -03:00",
  },
  { country: "AFRICA", timeZone: "Africa/Windhoek", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Karachi", utcOffset: "UTC +05:00" },
  { country: "AFRICA", timeZone: "Africa/Mogadishu", utcOffset: "UTC +03:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Perth",
    utcOffset: "UTC +08:00",
  },
  { country: "BRAZIL", timeZone: "Brazil/East", utcOffset: "UTC -03:00" },
  { country: "ETC", timeZone: "Etc/GMT", utcOffset: "UTC +00:00" },
  { country: "ASIA", timeZone: "Asia/Chita", utcOffset: "UTC +09:00" },
  { country: "PACIFIC", timeZone: "Pacific/Easter", utcOffset: "UTC -06:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Davis",
    utcOffset: "UTC +07:00",
  },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/McMurdo",
    utcOffset: "UTC +12:00",
  },
  { country: "ASIA", timeZone: "Asia/Macao", utcOffset: "UTC +08:00" },
  { country: "AMERICA", timeZone: "America/Manaus", utcOffset: "UTC -04:00" },
  { country: "AFRICA", timeZone: "Africa/Freetown", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Bucharest", utcOffset: "UTC +03:00" },
  { country: "ASIA", timeZone: "Asia/Tomsk", utcOffset: "UTC +07:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Mendoza",
    utcOffset: "UTC -03:00",
  },
  { country: "ASIA", timeZone: "Asia/Macau", utcOffset: "UTC +08:00" },
  { country: "EUROPE", timeZone: "Europe/Malta", utcOffset: "UTC +02:00" },
  { country: "MEXICO", timeZone: "Mexico/BajaSur", utcOffset: "UTC -07:00" },
  { country: "PACIFIC", timeZone: "Pacific/Tahiti", utcOffset: "UTC -10:00" },
  { country: "AFRICA", timeZone: "Africa/Asmera", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Busingen", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Rio_Gallegos",
    utcOffset: "UTC -03:00",
  },
  { country: "AFRICA", timeZone: "Africa/Malabo", utcOffset: "UTC +01:00" },
  { country: "EUROPE", timeZone: "Europe/Skopje", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Catamarca",
    utcOffset: "UTC -03:00",
  },
  { country: "AMERICA", timeZone: "America/Godthab", utcOffset: "UTC -01:00" },
  { country: "EUROPE", timeZone: "Europe/Sarajevo", utcOffset: "UTC +02:00" },
  { country: "AUSTRALIA", timeZone: "Australia/ACT", utcOffset: "UTC +10:00" },
  { country: "Unknown", timeZone: "GB-Eire", utcOffset: "UTC +01:00" },
  { country: "AFRICA", timeZone: "Africa/Lagos", utcOffset: "UTC +01:00" },
  { country: "AMERICA", timeZone: "America/Cordoba", utcOffset: "UTC -03:00" },
  { country: "EUROPE", timeZone: "Europe/Rome", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Dacca", utcOffset: "UTC +06:00" },
  { country: "INDIAN", timeZone: "Indian/Mauritius", utcOffset: "UTC +04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Samoa", utcOffset: "UTC -11:00" },
  { country: "AMERICA", timeZone: "America/Regina", utcOffset: "UTC -06:00" },
  {
    country: "AMERICA",
    timeZone: "America/Fort_Wayne",
    utcOffset: "UTC -04:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Dawson_Creek",
    utcOffset: "UTC -07:00",
  },
  { country: "AFRICA", timeZone: "Africa/Algiers", utcOffset: "UTC +01:00" },
  { country: "EUROPE", timeZone: "Europe/Mariehamn", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/St_Johns", utcOffset: "UTC -02:30" },
  {
    country: "AMERICA",
    timeZone: "America/St_Thomas",
    utcOffset: "UTC -04:00",
  },
  { country: "EUROPE", timeZone: "Europe/Zurich", utcOffset: "UTC +02:00" },
  { country: "AMERICA", timeZone: "America/Anguilla", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Dili", utcOffset: "UTC +09:00" },
  { country: "AMERICA", timeZone: "America/Denver", utcOffset: "UTC -06:00" },
  { country: "AFRICA", timeZone: "Africa/Bamako", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Saratov", utcOffset: "UTC +04:00" },
  { country: "Unknown", timeZone: "GB", utcOffset: "UTC +01:00" },
  { country: "MEXICO", timeZone: "Mexico/General", utcOffset: "UTC -06:00" },
  { country: "PACIFIC", timeZone: "Pacific/Wallis", utcOffset: "UTC +12:00" },
  { country: "EUROPE", timeZone: "Europe/Gibraltar", utcOffset: "UTC +02:00" },
  { country: "AFRICA", timeZone: "Africa/Conakry", utcOffset: "UTC +00:00" },
  { country: "AFRICA", timeZone: "Africa/Lubumbashi", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Istanbul", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Havana", utcOffset: "UTC -04:00" },
  { country: "Unknown", timeZone: "NZ-CHAT", utcOffset: "UTC +12:45" },
  { country: "ASIA", timeZone: "Asia/Choibalsan", utcOffset: "UTC +08:00" },
  {
    country: "AMERICA",
    timeZone: "America/Porto_Acre",
    utcOffset: "UTC -05:00",
  },
  { country: "ASIA", timeZone: "Asia/Omsk", utcOffset: "UTC +06:00" },
  { country: "EUROPE", timeZone: "Europe/Vaduz", utcOffset: "UTC +02:00" },
  {
    country: "United States",
    timeZone: "US/Michigan",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Dhaka", utcOffset: "UTC +06:00" },
  { country: "AMERICA", timeZone: "America/Barbados", utcOffset: "UTC -04:00" },
  { country: "EUROPE", timeZone: "Europe/Tiraspol", utcOffset: "UTC +03:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/Cape_Verde",
    utcOffset: "UTC -01:00",
  },
  { country: "ASIA", timeZone: "Asia/Yekaterinburg", utcOffset: "UTC +05:00" },
  {
    country: "AMERICA",
    timeZone: "America/Louisville",
    utcOffset: "UTC -04:00",
  },
  { country: "PACIFIC", timeZone: "Pacific/Johnston", utcOffset: "UTC -10:00" },
  { country: "PACIFIC", timeZone: "Pacific/Chatham", utcOffset: "UTC +12:45" },
  { country: "EUROPE", timeZone: "Europe/Ljubljana", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Sao_Paulo",
    utcOffset: "UTC -03:00",
  },
  { country: "ASIA", timeZone: "Asia/Jayapura", utcOffset: "UTC +09:00" },
  { country: "AMERICA", timeZone: "America/Curacao", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Dushanbe", utcOffset: "UTC +05:00" },
  { country: "AMERICA", timeZone: "America/Guyana", utcOffset: "UTC -04:00" },
  {
    country: "AMERICA",
    timeZone: "America/Guayaquil",
    utcOffset: "UTC -05:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Martinique",
    utcOffset: "UTC -04:00",
  },
  { country: "Unknown", timeZone: "Portugal", utcOffset: "UTC +01:00" },
  { country: "EUROPE", timeZone: "Europe/Berlin", utcOffset: "UTC +02:00" },
  { country: "EUROPE", timeZone: "Europe/Moscow", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Chisinau", utcOffset: "UTC +03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Puerto_Rico",
    utcOffset: "UTC -04:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Rankin_Inlet",
    utcOffset: "UTC -05:00",
  },
  { country: "PACIFIC", timeZone: "Pacific/Ponape", utcOffset: "UTC +11:00" },
  { country: "EUROPE", timeZone: "Europe/Stockholm", utcOffset: "UTC +02:00" },
  { country: "EUROPE", timeZone: "Europe/Budapest", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Jujuy",
    utcOffset: "UTC -03:00",
  },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Eucla",
    utcOffset: "UTC +08:45",
  },
  { country: "ASIA", timeZone: "Asia/Shanghai", utcOffset: "UTC +08:00" },
  { country: "Unknown", timeZone: "Universal", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Zagreb", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Port_of_Spain",
    utcOffset: "UTC -04:00",
  },
  { country: "EUROPE", timeZone: "Europe/Helsinki", utcOffset: "UTC +03:00" },
  { country: "ASIA", timeZone: "Asia/Beirut", utcOffset: "UTC +03:00" },
  { country: "ASIA", timeZone: "Asia/Tel_Aviv", utcOffset: "UTC +03:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Bougainville",
    utcOffset: "UTC +11:00",
  },
  { country: "United States", timeZone: "US/Central", utcOffset: "UTC -05:00" },
  { country: "AFRICA", timeZone: "Africa/Sao_Tome", utcOffset: "UTC +00:00" },
  { country: "INDIAN", timeZone: "Indian/Chagos", utcOffset: "UTC +06:00" },
  { country: "AMERICA", timeZone: "America/Cayenne", utcOffset: "UTC -03:00" },
  { country: "ASIA", timeZone: "Asia/Yakutsk", utcOffset: "UTC +09:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Galapagos",
    utcOffset: "UTC -06:00",
  },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/North",
    utcOffset: "UTC +09:30",
  },
  { country: "EUROPE", timeZone: "Europe/Paris", utcOffset: "UTC +02:00" },
  { country: "AFRICA", timeZone: "Africa/Ndjamena", utcOffset: "UTC +01:00" },
  { country: "PACIFIC", timeZone: "Pacific/Fiji", utcOffset: "UTC +12:00" },
  {
    country: "AMERICA",
    timeZone: "America/Rainy_River",
    utcOffset: "UTC -05:00",
  },
  { country: "INDIAN", timeZone: "Indian/Maldives", utcOffset: "UTC +05:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Yancowinna",
    utcOffset: "UTC +09:30",
  },
  { country: "SYSTEMV", timeZone: "SystemV/AST4", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Oral", utcOffset: "UTC +05:00" },
  {
    country: "AMERICA",
    timeZone: "America/Yellowknife",
    utcOffset: "UTC -06:00",
  },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Enderbury",
    utcOffset: "UTC +13:00",
  },
  { country: "AMERICA", timeZone: "America/Juneau", utcOffset: "UTC -08:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Victoria",
    utcOffset: "UTC +10:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Vevay",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Tashkent", utcOffset: "UTC +05:00" },
  { country: "ASIA", timeZone: "Asia/Jakarta", utcOffset: "UTC +07:00" },
  { country: "AFRICA", timeZone: "Africa/Ceuta", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Barnaul", utcOffset: "UTC +07:00" },
  { country: "AMERICA", timeZone: "America/Recife", utcOffset: "UTC -03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Buenos_Aires",
    utcOffset: "UTC -03:00",
  },
  { country: "AMERICA", timeZone: "America/Noronha", utcOffset: "UTC -02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Swift_Current",
    utcOffset: "UTC -06:00",
  },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Adelaide",
    utcOffset: "UTC +09:30",
  },
  {
    country: "AMERICA",
    timeZone: "America/Metlakatla",
    utcOffset: "UTC -08:00",
  },
  { country: "AFRICA", timeZone: "Africa/Djibouti", utcOffset: "UTC +03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Paramaribo",
    utcOffset: "UTC -03:00",
  },
  { country: "ASIA", timeZone: "Asia/Qostanay", utcOffset: "UTC +05:00" },
  { country: "EUROPE", timeZone: "Europe/Simferopol", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Sofia", utcOffset: "UTC +03:00" },
  { country: "AFRICA", timeZone: "Africa/Nouakchott", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Prague", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Vincennes",
    utcOffset: "UTC -04:00",
  },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Mawson",
    utcOffset: "UTC +05:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Kralendijk",
    utcOffset: "UTC -04:00",
  },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Troll",
    utcOffset: "UTC +02:00",
  },
  { country: "EUROPE", timeZone: "Europe/Samara", utcOffset: "UTC +04:00" },
  { country: "INDIAN", timeZone: "Indian/Christmas", utcOffset: "UTC +07:00" },
  { country: "AMERICA", timeZone: "America/Antigua", utcOffset: "UTC -04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Gambier", utcOffset: "UTC -09:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indianapolis",
    utcOffset: "UTC -04:00",
  },
  { country: "AMERICA", timeZone: "America/Inuvik", utcOffset: "UTC -06:00" },
  { country: "AMERICA", timeZone: "America/Iqaluit", utcOffset: "UTC -04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Funafuti", utcOffset: "UTC +12:00" },
  { country: "Unknown", timeZone: "UTC", utcOffset: "UTC +00:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Macquarie",
    utcOffset: "UTC +10:00",
  },
  { country: "CANADA", timeZone: "Canada/Pacific", utcOffset: "UTC -07:00" },
  { country: "AMERICA", timeZone: "America/Moncton", utcOffset: "UTC -03:00" },
  { country: "AFRICA", timeZone: "Africa/Gaborone", utcOffset: "UTC +02:00" },
  { country: "PACIFIC", timeZone: "Pacific/Chuuk", utcOffset: "UTC +10:00" },
  { country: "ASIA", timeZone: "Asia/Pyongyang", utcOffset: "UTC +09:00" },
  {
    country: "AMERICA",
    timeZone: "America/St_Vincent",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Gaza", utcOffset: "UTC +03:00" },
  { country: "ETC", timeZone: "Etc/Universal", utcOffset: "UTC +00:00" },
  { country: "Unknown", timeZone: "PST8PDT", utcOffset: "UTC -07:00" },
  { country: "ATLANTIC", timeZone: "Atlantic/Faeroe", utcOffset: "UTC +01:00" },
  { country: "ASIA", timeZone: "Asia/Qyzylorda", utcOffset: "UTC +05:00" },
  {
    country: "CANADA",
    timeZone: "Canada/Newfoundland",
    utcOffset: "UTC -02:30",
  },
  {
    country: "AMERICA",
    timeZone: "America/Kentucky/Louisville",
    utcOffset: "UTC -04:00",
  },
  { country: "AMERICA", timeZone: "America/Yakutat", utcOffset: "UTC -08:00" },
  {
    country: "AMERICA",
    timeZone: "America/Ciudad_Juarez",
    utcOffset: "UTC -06:00",
  },
  { country: "ASIA", timeZone: "Asia/Ho_Chi_Minh", utcOffset: "UTC +07:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Casey",
    utcOffset: "UTC +08:00",
  },
  { country: "EUROPE", timeZone: "Europe/Copenhagen", utcOffset: "UTC +02:00" },
  { country: "AFRICA", timeZone: "Africa/Asmara", utcOffset: "UTC +03:00" },
  { country: "ATLANTIC", timeZone: "Atlantic/Azores", utcOffset: "UTC +00:00" },
  { country: "EUROPE", timeZone: "Europe/Vienna", utcOffset: "UTC +02:00" },
  { country: "Unknown", timeZone: "ROK", utcOffset: "UTC +09:00" },
  { country: "PACIFIC", timeZone: "Pacific/Pitcairn", utcOffset: "UTC -08:00" },
  { country: "AMERICA", timeZone: "America/Mazatlan", utcOffset: "UTC -07:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Queensland",
    utcOffset: "UTC +10:00",
  },
  { country: "PACIFIC", timeZone: "Pacific/Nauru", utcOffset: "UTC +12:00" },
  { country: "EUROPE", timeZone: "Europe/Tirane", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Kolkata", utcOffset: "UTC +05:30" },
  { country: "SYSTEMV", timeZone: "SystemV/MST7", utcOffset: "UTC -07:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Canberra",
    utcOffset: "UTC +10:00",
  },
  { country: "Unknown", timeZone: "MET", utcOffset: "UTC +02:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Broken_Hill",
    utcOffset: "UTC +09:30",
  },
  { country: "EUROPE", timeZone: "Europe/Riga", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Dominica", utcOffset: "UTC -04:00" },
  { country: "AFRICA", timeZone: "Africa/Abidjan", utcOffset: "UTC +00:00" },
  { country: "AMERICA", timeZone: "America/Mendoza", utcOffset: "UTC -03:00" },
  { country: "AMERICA", timeZone: "America/Santarem", utcOffset: "UTC -03:00" },
  { country: "Unknown", timeZone: "Kwajalein", utcOffset: "UTC +12:00" },
  { country: "AMERICA", timeZone: "America/Asuncion", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Ulan_Bator", utcOffset: "UTC +08:00" },
  { country: "Unknown", timeZone: "NZ", utcOffset: "UTC +12:00" },
  { country: "AMERICA", timeZone: "America/Boise", utcOffset: "UTC -06:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Currie",
    utcOffset: "UTC +10:00",
  },
  { country: "Unknown", timeZone: "EST5EDT", utcOffset: "UTC -04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Guam", utcOffset: "UTC +10:00" },
  { country: "PACIFIC", timeZone: "Pacific/Wake", utcOffset: "UTC +12:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/Bermuda",
    utcOffset: "UTC -03:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Costa_Rica",
    utcOffset: "UTC -06:00",
  },
  { country: "AMERICA", timeZone: "America/Dawson", utcOffset: "UTC -07:00" },
  { country: "ASIA", timeZone: "Asia/Chongqing", utcOffset: "UTC +08:00" },
  { country: "Unknown", timeZone: "Eire", utcOffset: "UTC +01:00" },
  { country: "EUROPE", timeZone: "Europe/Amsterdam", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Knox",
    utcOffset: "UTC -05:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/North_Dakota/Beulah",
    utcOffset: "UTC -05:00",
  },
  { country: "AFRICA", timeZone: "Africa/Accra", utcOffset: "UTC +00:00" },
  { country: "ATLANTIC", timeZone: "Atlantic/Faroe", utcOffset: "UTC +01:00" },
  { country: "MEXICO", timeZone: "Mexico/BajaNorte", utcOffset: "UTC -07:00" },
  { country: "AMERICA", timeZone: "America/Maceio", utcOffset: "UTC -03:00" },
  { country: "ETC", timeZone: "Etc/UCT", utcOffset: "UTC +00:00" },
  { country: "PACIFIC", timeZone: "Pacific/Apia", utcOffset: "UTC +13:00" },
  { country: "Unknown", timeZone: "GMT0", utcOffset: "UTC +00:00" },
  { country: "AMERICA", timeZone: "America/Atka", utcOffset: "UTC -09:00" },
  { country: "PACIFIC", timeZone: "Pacific/Niue", utcOffset: "UTC -11:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Lord_Howe",
    utcOffset: "UTC +10:30",
  },
  { country: "EUROPE", timeZone: "Europe/Dublin", utcOffset: "UTC +01:00" },
  { country: "PACIFIC", timeZone: "Pacific/Truk", utcOffset: "UTC +10:00" },
  { country: "Unknown", timeZone: "MST7MDT", utcOffset: "UTC -06:00" },
  {
    country: "AMERICA",
    timeZone: "America/Monterrey",
    utcOffset: "UTC -06:00",
  },
  { country: "AMERICA", timeZone: "America/Nassau", utcOffset: "UTC -04:00" },
  { country: "AMERICA", timeZone: "America/Jamaica", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Bishkek", utcOffset: "UTC +06:00" },
  { country: "AMERICA", timeZone: "America/Atikokan", utcOffset: "UTC -05:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/Stanley",
    utcOffset: "UTC -03:00",
  },
  { country: "AUSTRALIA", timeZone: "Australia/NSW", utcOffset: "UTC +10:00" },
  { country: "United States", timeZone: "US/Hawaii", utcOffset: "UTC -10:00" },
  { country: "SYSTEMV", timeZone: "SystemV/CST6", utcOffset: "UTC -06:00" },
  { country: "INDIAN", timeZone: "Indian/Mahe", utcOffset: "UTC +04:00" },
  { country: "ASIA", timeZone: "Asia/Aqtobe", utcOffset: "UTC +05:00" },
  { country: "AMERICA", timeZone: "America/Sitka", utcOffset: "UTC -08:00" },
  { country: "ASIA", timeZone: "Asia/Vladivostok", utcOffset: "UTC +10:00" },
  { country: "AFRICA", timeZone: "Africa/Libreville", utcOffset: "UTC +01:00" },
  { country: "AFRICA", timeZone: "Africa/Maputo", utcOffset: "UTC +02:00" },
  { country: "Unknown", timeZone: "Zulu", utcOffset: "UTC +00:00" },
  {
    country: "AMERICA",
    timeZone: "America/Kentucky/Monticello",
    utcOffset: "UTC -04:00",
  },
  { country: "AFRICA", timeZone: "Africa/El_Aaiun", utcOffset: "UTC +01:00" },
  {
    country: "AFRICA",
    timeZone: "Africa/Ouagadougou",
    utcOffset: "UTC +00:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Coral_Harbour",
    utcOffset: "UTC -05:00",
  },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Marquesas",
    utcOffset: "UTC -09:30",
  },
  { country: "BRAZIL", timeZone: "Brazil/West", utcOffset: "UTC -04:00" },
  { country: "AMERICA", timeZone: "America/Aruba", utcOffset: "UTC -04:00" },
  {
    country: "AMERICA",
    timeZone: "America/North_Dakota/Center",
    utcOffset: "UTC -05:00",
  },
  { country: "AMERICA", timeZone: "America/Cayman", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Ulaanbaatar", utcOffset: "UTC +08:00" },
  { country: "ASIA", timeZone: "Asia/Baghdad", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/San_Marino", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Tell_City",
    utcOffset: "UTC -05:00",
  },
  { country: "AMERICA", timeZone: "America/Tijuana", utcOffset: "UTC -07:00" },
  { country: "PACIFIC", timeZone: "Pacific/Saipan", utcOffset: "UTC +10:00" },
  { country: "SYSTEMV", timeZone: "SystemV/YST9", utcOffset: "UTC -09:00" },
  { country: "AFRICA", timeZone: "Africa/Douala", utcOffset: "UTC +01:00" },
  {
    country: "AMERICA",
    timeZone: "America/Chihuahua",
    utcOffset: "UTC -06:00",
  },
  { country: "AMERICA", timeZone: "America/Ojinaga", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Hovd", utcOffset: "UTC +07:00" },
  {
    country: "AMERICA",
    timeZone: "America/Anchorage",
    utcOffset: "UTC -08:00",
  },
  { country: "CHILE", timeZone: "Chile/EasterIsland", utcOffset: "UTC -06:00" },
  { country: "AMERICA", timeZone: "America/Halifax", utcOffset: "UTC -03:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Rothera",
    utcOffset: "UTC -03:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Indianapolis",
    utcOffset: "UTC -04:00",
  },
  {
    country: "United States",
    timeZone: "US/Mountain",
    utcOffset: "UTC -06:00",
  },
  { country: "ASIA", timeZone: "Asia/Damascus", utcOffset: "UTC +03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/San_Luis",
    utcOffset: "UTC -03:00",
  },
  { country: "AMERICA", timeZone: "America/Santiago", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Baku", utcOffset: "UTC +04:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Ushuaia",
    utcOffset: "UTC -03:00",
  },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/Reykjavik",
    utcOffset: "UTC +00:00",
  },
  {
    country: "AFRICA",
    timeZone: "Africa/Brazzaville",
    utcOffset: "UTC +01:00",
  },
  { country: "AFRICA", timeZone: "Africa/Porto-Novo", utcOffset: "UTC +01:00" },
  { country: "AMERICA", timeZone: "America/La_Paz", utcOffset: "UTC -04:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/DumontDUrville",
    utcOffset: "UTC +10:00",
  },
  { country: "ASIA", timeZone: "Asia/Taipei", utcOffset: "UTC +08:00" },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/South_Pole",
    utcOffset: "UTC +12:00",
  },
  { country: "ASIA", timeZone: "Asia/Manila", utcOffset: "UTC +08:00" },
  { country: "ASIA", timeZone: "Asia/Bangkok", utcOffset: "UTC +07:00" },
  {
    country: "AFRICA",
    timeZone: "Africa/Dar_es_Salaam",
    utcOffset: "UTC +03:00",
  },
  { country: "Unknown", timeZone: "Poland", utcOffset: "UTC +02:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/Madeira",
    utcOffset: "UTC +01:00",
  },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Palmer",
    utcOffset: "UTC -03:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Thunder_Bay",
    utcOffset: "UTC -04:00",
  },
  {
    country: "AFRICA",
    timeZone: "Africa/Addis_Ababa",
    utcOffset: "UTC +03:00",
  },
  { country: "ASIA", timeZone: "Asia/Yangon", utcOffset: "UTC +06:30" },
  { country: "EUROPE", timeZone: "Europe/Uzhgorod", utcOffset: "UTC +03:00" },
  { country: "BRAZIL", timeZone: "Brazil/DeNoronha", utcOffset: "UTC -02:00" },
  { country: "ASIA", timeZone: "Asia/Ashkhabad", utcOffset: "UTC +05:00" },
  { country: "ETC", timeZone: "Etc/Zulu", utcOffset: "UTC +00:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Marengo",
    utcOffset: "UTC -04:00",
  },
  { country: "AMERICA", timeZone: "America/Creston", utcOffset: "UTC -07:00" },
  {
    country: "AMERICA",
    timeZone: "America/Punta_Arenas",
    utcOffset: "UTC -03:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Mexico_City",
    utcOffset: "UTC -06:00",
  },
  {
    country: "ANTARCTICA",
    timeZone: "Antarctica/Vostok",
    utcOffset: "UTC +05:00",
  },
  { country: "ASIA", timeZone: "Asia/Jerusalem", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Andorra", utcOffset: "UTC +02:00" },
  { country: "United States", timeZone: "US/Samoa", utcOffset: "UTC -11:00" },
  { country: "Unknown", timeZone: "PRC", utcOffset: "UTC +08:00" },
  { country: "ASIA", timeZone: "Asia/Vientiane", utcOffset: "UTC +07:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Kiritimati",
    utcOffset: "UTC +14:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Matamoros",
    utcOffset: "UTC -05:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Blanc-Sablon",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Riyadh", utcOffset: "UTC +03:00" },
  { country: "Unknown", timeZone: "Iceland", utcOffset: "UTC +00:00" },
  { country: "PACIFIC", timeZone: "Pacific/Pohnpei", utcOffset: "UTC +11:00" },
  { country: "ASIA", timeZone: "Asia/Ujung_Pandang", utcOffset: "UTC +08:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/South_Georgia",
    utcOffset: "UTC -02:00",
  },
  { country: "EUROPE", timeZone: "Europe/Lisbon", utcOffset: "UTC +01:00" },
  { country: "ASIA", timeZone: "Asia/Harbin", utcOffset: "UTC +08:00" },
  { country: "EUROPE", timeZone: "Europe/Oslo", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Novokuznetsk", utcOffset: "UTC +07:00" },
  { country: "Unknown", timeZone: "CST6CDT", utcOffset: "UTC -05:00" },
  { country: "ATLANTIC", timeZone: "Atlantic/Canary", utcOffset: "UTC +01:00" },
  { country: "AMERICA", timeZone: "America/Knox_IN", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Kuwait", utcOffset: "UTC +03:00" },
  { country: "SYSTEMV", timeZone: "SystemV/HST10", utcOffset: "UTC -10:00" },
  { country: "PACIFIC", timeZone: "Pacific/Efate", utcOffset: "UTC +11:00" },
  { country: "AFRICA", timeZone: "Africa/Lome", utcOffset: "UTC +00:00" },
  { country: "AMERICA", timeZone: "America/Bogota", utcOffset: "UTC -05:00" },
  {
    country: "AMERICA",
    timeZone: "America/Menominee",
    utcOffset: "UTC -05:00",
  },
  { country: "AMERICA", timeZone: "America/Adak", utcOffset: "UTC -09:00" },
  { country: "PACIFIC", timeZone: "Pacific/Norfolk", utcOffset: "UTC +11:00" },
  { country: "EUROPE", timeZone: "Europe/Kirov", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Resolute", utcOffset: "UTC -05:00" },
  { country: "PACIFIC", timeZone: "Pacific/Kanton", utcOffset: "UTC +13:00" },
  { country: "PACIFIC", timeZone: "Pacific/Tarawa", utcOffset: "UTC +12:00" },
  { country: "AFRICA", timeZone: "Africa/Kampala", utcOffset: "UTC +03:00" },
  { country: "ASIA", timeZone: "Asia/Krasnoyarsk", utcOffset: "UTC +07:00" },
  { country: "Unknown", timeZone: "Greenwich", utcOffset: "UTC +00:00" },
  { country: "SYSTEMV", timeZone: "SystemV/EST5", utcOffset: "UTC -05:00" },
  { country: "AMERICA", timeZone: "America/Edmonton", utcOffset: "UTC -06:00" },
  { country: "EUROPE", timeZone: "Europe/Podgorica", utcOffset: "UTC +02:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/South",
    utcOffset: "UTC +09:30",
  },
  { country: "CANADA", timeZone: "Canada/Central", utcOffset: "UTC -05:00" },
  { country: "AFRICA", timeZone: "Africa/Bujumbura", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Santo_Domingo",
    utcOffset: "UTC -04:00",
  },
  { country: "United States", timeZone: "US/Eastern", utcOffset: "UTC -04:00" },
  { country: "EUROPE", timeZone: "Europe/Minsk", utcOffset: "UTC +03:00" },
  { country: "PACIFIC", timeZone: "Pacific/Auckland", utcOffset: "UTC +12:00" },
  { country: "AFRICA", timeZone: "Africa/Casablanca", utcOffset: "UTC +01:00" },
  {
    country: "AMERICA",
    timeZone: "America/Glace_Bay",
    utcOffset: "UTC -03:00",
  },
  { country: "CANADA", timeZone: "Canada/Eastern", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Qatar", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Kiev", utcOffset: "UTC +03:00" },
  { country: "Unknown", timeZone: "Singapore", utcOffset: "UTC +08:00" },
  { country: "ASIA", timeZone: "Asia/Magadan", utcOffset: "UTC +11:00" },
  { country: "SYSTEMV", timeZone: "SystemV/PST8", utcOffset: "UTC -08:00" },
  {
    country: "AMERICA",
    timeZone: "America/Port-au-Prince",
    utcOffset: "UTC -04:00",
  },
  { country: "EUROPE", timeZone: "Europe/Belfast", utcOffset: "UTC +01:00" },
  {
    country: "AMERICA",
    timeZone: "America/St_Barthelemy",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Ashgabat", utcOffset: "UTC +05:00" },
  { country: "AFRICA", timeZone: "Africa/Luanda", utcOffset: "UTC +01:00" },
  { country: "AMERICA", timeZone: "America/Nipigon", utcOffset: "UTC -04:00" },
  {
    country: "ATLANTIC",
    timeZone: "Atlantic/Jan_Mayen",
    utcOffset: "UTC +02:00",
  },
  { country: "BRAZIL", timeZone: "Brazil/Acre", utcOffset: "UTC -05:00" },
  { country: "ASIA", timeZone: "Asia/Muscat", utcOffset: "UTC +04:00" },
  { country: "ASIA", timeZone: "Asia/Bahrain", utcOffset: "UTC +03:00" },
  { country: "EUROPE", timeZone: "Europe/Vilnius", utcOffset: "UTC +03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Fortaleza",
    utcOffset: "UTC -03:00",
  },
  { country: "ETC", timeZone: "Etc/GMT0", utcOffset: "UTC +00:00" },
  {
    country: "United States",
    timeZone: "US/East-Indiana",
    utcOffset: "UTC -04:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Hermosillo",
    utcOffset: "UTC -07:00",
  },
  { country: "AMERICA", timeZone: "America/Cancun", utcOffset: "UTC -05:00" },
  { country: "AFRICA", timeZone: "Africa/Maseru", utcOffset: "UTC +02:00" },
  { country: "PACIFIC", timeZone: "Pacific/Kosrae", utcOffset: "UTC +11:00" },
  { country: "AFRICA", timeZone: "Africa/Kinshasa", utcOffset: "UTC +01:00" },
  { country: "ASIA", timeZone: "Asia/Kathmandu", utcOffset: "UTC +05:45" },
  { country: "ASIA", timeZone: "Asia/Seoul", utcOffset: "UTC +09:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Sydney",
    utcOffset: "UTC +10:00",
  },
  { country: "AMERICA", timeZone: "America/Lima", utcOffset: "UTC -05:00" },
  { country: "AUSTRALIA", timeZone: "Australia/LHI", utcOffset: "UTC +10:30" },
  { country: "AMERICA", timeZone: "America/St_Lucia", utcOffset: "UTC -04:00" },
  { country: "EUROPE", timeZone: "Europe/Madrid", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Bahia_Banderas",
    utcOffset: "UTC -06:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Montserrat",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Brunei", utcOffset: "UTC +08:00" },
  {
    country: "AMERICA",
    timeZone: "America/Santa_Isabel",
    utcOffset: "UTC -07:00",
  },
  { country: "CANADA", timeZone: "Canada/Mountain", utcOffset: "UTC -06:00" },
  {
    country: "AMERICA",
    timeZone: "America/Cambridge_Bay",
    utcOffset: "UTC -06:00",
  },
  { country: "ASIA", timeZone: "Asia/Colombo", utcOffset: "UTC +05:30" },
  { country: "AUSTRALIA", timeZone: "Australia/West", utcOffset: "UTC +08:00" },
  {
    country: "INDIAN",
    timeZone: "Indian/Antananarivo",
    utcOffset: "UTC +03:00",
  },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Brisbane",
    utcOffset: "UTC +10:00",
  },
  { country: "INDIAN", timeZone: "Indian/Mayotte", utcOffset: "UTC +03:00" },
  {
    country: "United States",
    timeZone: "US/Indiana-Starke",
    utcOffset: "UTC -05:00",
  },
  { country: "ASIA", timeZone: "Asia/Urumqi", utcOffset: "UTC +06:00" },
  {
    country: "United States",
    timeZone: "US/Aleutian",
    utcOffset: "UTC -09:00",
  },
  { country: "EUROPE", timeZone: "Europe/Volgograd", utcOffset: "UTC +03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Lower_Princes",
    utcOffset: "UTC -04:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Vancouver",
    utcOffset: "UTC -07:00",
  },
  { country: "AFRICA", timeZone: "Africa/Blantyre", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Rio_Branco",
    utcOffset: "UTC -05:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Danmarkshavn",
    utcOffset: "UTC +00:00",
  },
  { country: "AMERICA", timeZone: "America/Detroit", utcOffset: "UTC -04:00" },
  { country: "AMERICA", timeZone: "America/Thule", utcOffset: "UTC -03:00" },
  { country: "AFRICA", timeZone: "Africa/Lusaka", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Hong_Kong", utcOffset: "UTC +08:00" },
  { country: "Unknown", timeZone: "Iran", utcOffset: "UTC +03:30" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/La_Rioja",
    utcOffset: "UTC -03:00",
  },
  { country: "AFRICA", timeZone: "Africa/Dakar", utcOffset: "UTC +00:00" },
  { country: "SYSTEMV", timeZone: "SystemV/CST6CDT", utcOffset: "UTC -05:00" },
  { country: "AMERICA", timeZone: "America/Tortola", utcOffset: "UTC -04:00" },
  {
    country: "AMERICA",
    timeZone: "America/Porto_Velho",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Sakhalin", utcOffset: "UTC +11:00" },
  { country: "ETC", timeZone: "Etc/GMT+10", utcOffset: "UTC -10:00" },
  {
    country: "AMERICA",
    timeZone: "America/Scoresbysund",
    utcOffset: "UTC -01:00",
  },
  { country: "ASIA", timeZone: "Asia/Kamchatka", utcOffset: "UTC +12:00" },
  { country: "ASIA", timeZone: "Asia/Thimbu", utcOffset: "UTC +06:00" },
  { country: "AFRICA", timeZone: "Africa/Harare", utcOffset: "UTC +02:00" },
  { country: "ETC", timeZone: "Etc/GMT+12", utcOffset: "UTC -12:00" },
  { country: "ETC", timeZone: "Etc/GMT+11", utcOffset: "UTC -11:00" },
  { country: "Unknown", timeZone: "Navajo", utcOffset: "UTC -06:00" },
  { country: "AMERICA", timeZone: "America/Nome", utcOffset: "UTC -08:00" },
  { country: "EUROPE", timeZone: "Europe/Tallinn", utcOffset: "UTC +03:00" },
  { country: "Unknown", timeZone: "Turkey", utcOffset: "UTC +03:00" },
  { country: "AFRICA", timeZone: "Africa/Khartoum", utcOffset: "UTC +02:00" },
  {
    country: "AFRICA",
    timeZone: "Africa/Johannesburg",
    utcOffset: "UTC +02:00",
  },
  { country: "AFRICA", timeZone: "Africa/Bangui", utcOffset: "UTC +01:00" },
  { country: "EUROPE", timeZone: "Europe/Belgrade", utcOffset: "UTC +02:00" },
  { country: "Unknown", timeZone: "Jamaica", utcOffset: "UTC -05:00" },
  { country: "AFRICA", timeZone: "Africa/Bissau", utcOffset: "UTC +00:00" },
  { country: "ASIA", timeZone: "Asia/Tehran", utcOffset: "UTC +03:30" },
  { country: "Unknown", timeZone: "WET", utcOffset: "UTC +01:00" },
  { country: "EUROPE", timeZone: "Europe/Astrakhan", utcOffset: "UTC +04:00" },
  { country: "AFRICA", timeZone: "Africa/Juba", utcOffset: "UTC +02:00" },
  {
    country: "AMERICA",
    timeZone: "America/Campo_Grande",
    utcOffset: "UTC -04:00",
  },
  { country: "AMERICA", timeZone: "America/Belem", utcOffset: "UTC -03:00" },
  { country: "ETC", timeZone: "Etc/Greenwich", utcOffset: "UTC +00:00" },
  { country: "ASIA", timeZone: "Asia/Saigon", utcOffset: "UTC +07:00" },
  { country: "AMERICA", timeZone: "America/Ensenada", utcOffset: "UTC -07:00" },
  { country: "PACIFIC", timeZone: "Pacific/Midway", utcOffset: "UTC -11:00" },
  { country: "AMERICA", timeZone: "America/Jujuy", utcOffset: "UTC -03:00" },
  { country: "AFRICA", timeZone: "Africa/Timbuktu", utcOffset: "UTC +00:00" },
  { country: "AMERICA", timeZone: "America/Bahia", utcOffset: "UTC -03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Goose_Bay",
    utcOffset: "UTC -03:00",
  },
  { country: "AMERICA", timeZone: "America/Virgin", utcOffset: "UTC -04:00" },
  {
    country: "AMERICA",
    timeZone: "America/Pangnirtung",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Katmandu", utcOffset: "UTC +05:45" },
  { country: "AMERICA", timeZone: "America/Phoenix", utcOffset: "UTC -07:00" },
  { country: "AFRICA", timeZone: "Africa/Niamey", utcOffset: "UTC +01:00" },
  {
    country: "AMERICA",
    timeZone: "America/Whitehorse",
    utcOffset: "UTC -07:00",
  },
  { country: "PACIFIC", timeZone: "Pacific/Noumea", utcOffset: "UTC +11:00" },
  { country: "ASIA", timeZone: "Asia/Tbilisi", utcOffset: "UTC +04:00" },
  { country: "EUROPE", timeZone: "Europe/Kyiv", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Montreal", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Makassar", utcOffset: "UTC +08:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/San_Juan",
    utcOffset: "UTC -03:00",
  },
  { country: "Unknown", timeZone: "Hongkong", utcOffset: "UTC +08:00" },
  { country: "Unknown", timeZone: "UCT", utcOffset: "UTC +00:00" },
  { country: "ASIA", timeZone: "Asia/Nicosia", utcOffset: "UTC +03:00" },
  {
    country: "AMERICA",
    timeZone: "America/Indiana/Winamac",
    utcOffset: "UTC -04:00",
  },
  { country: "SYSTEMV", timeZone: "SystemV/MST7MDT", utcOffset: "UTC -06:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/ComodRivadavia",
    utcOffset: "UTC -03:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Boa_Vista",
    utcOffset: "UTC -04:00",
  },
  { country: "AMERICA", timeZone: "America/Grenada", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Atyrau", utcOffset: "UTC +05:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Darwin",
    utcOffset: "UTC +09:30",
  },
  { country: "ASIA", timeZone: "Asia/Khandyga", utcOffset: "UTC +09:00" },
  { country: "ASIA", timeZone: "Asia/Kuala_Lumpur", utcOffset: "UTC +08:00" },
  { country: "ASIA", timeZone: "Asia/Famagusta", utcOffset: "UTC +03:00" },
  { country: "ASIA", timeZone: "Asia/Thimphu", utcOffset: "UTC +06:00" },
  { country: "ASIA", timeZone: "Asia/Rangoon", utcOffset: "UTC +06:30" },
  { country: "EUROPE", timeZone: "Europe/Bratislava", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Calcutta", utcOffset: "UTC +05:30" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Tucuman",
    utcOffset: "UTC -03:00",
  },
  { country: "ASIA", timeZone: "Asia/Kabul", utcOffset: "UTC +04:30" },
  { country: "INDIAN", timeZone: "Indian/Cocos", utcOffset: "UTC +06:30" },
  { country: "Unknown", timeZone: "Japan", utcOffset: "UTC +09:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Tongatapu",
    utcOffset: "UTC +13:00",
  },
  { country: "AMERICA", timeZone: "America/New_York", utcOffset: "UTC -04:00" },
  { country: "ETC", timeZone: "Etc/GMT-12", utcOffset: "UTC +12:00" },
  { country: "ETC", timeZone: "Etc/GMT-11", utcOffset: "UTC +11:00" },
  { country: "AMERICA", timeZone: "America/Nuuk", utcOffset: "UTC -01:00" },
  { country: "ETC", timeZone: "Etc/GMT-10", utcOffset: "UTC +10:00" },
  { country: "SYSTEMV", timeZone: "SystemV/YST9YDT", utcOffset: "UTC -08:00" },
  { country: "EUROPE", timeZone: "Europe/Ulyanovsk", utcOffset: "UTC +04:00" },
  { country: "ETC", timeZone: "Etc/GMT-14", utcOffset: "UTC +14:00" },
  { country: "ETC", timeZone: "Etc/GMT-13", utcOffset: "UTC +13:00" },
  { country: "Unknown", timeZone: "W-SU", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Merida", utcOffset: "UTC -06:00" },
  { country: "Unknown", timeZone: "EET", utcOffset: "UTC +03:00" },
  { country: "AMERICA", timeZone: "America/Rosario", utcOffset: "UTC -03:00" },
  {
    country: "CANADA",
    timeZone: "Canada/Saskatchewan",
    utcOffset: "UTC -06:00",
  },
  { country: "AMERICA", timeZone: "America/St_Kitts", utcOffset: "UTC -04:00" },
  {
    country: "ARCTIC",
    timeZone: "Arctic/Longyearbyen",
    utcOffset: "UTC +02:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Fort_Nelson",
    utcOffset: "UTC -07:00",
  },
  { country: "AMERICA", timeZone: "America/Caracas", utcOffset: "UTC -04:00" },
  {
    country: "AMERICA",
    timeZone: "America/Guadeloupe",
    utcOffset: "UTC -04:00",
  },
  { country: "ASIA", timeZone: "Asia/Hebron", utcOffset: "UTC +03:00" },
  { country: "INDIAN", timeZone: "Indian/Kerguelen", utcOffset: "UTC +05:00" },
  { country: "SYSTEMV", timeZone: "SystemV/PST8PDT", utcOffset: "UTC -07:00" },
  { country: "AFRICA", timeZone: "Africa/Monrovia", utcOffset: "UTC +00:00" },
  { country: "ASIA", timeZone: "Asia/Ust-Nera", utcOffset: "UTC +10:00" },
  { country: "Unknown", timeZone: "Egypt", utcOffset: "UTC +03:00" },
  { country: "ASIA", timeZone: "Asia/Srednekolymsk", utcOffset: "UTC +11:00" },
  {
    country: "AMERICA",
    timeZone: "America/North_Dakota/New_Salem",
    utcOffset: "UTC -05:00",
  },
  { country: "ASIA", timeZone: "Asia/Anadyr", utcOffset: "UTC +12:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Melbourne",
    utcOffset: "UTC +10:00",
  },
  { country: "ASIA", timeZone: "Asia/Irkutsk", utcOffset: "UTC +08:00" },
  { country: "AMERICA", timeZone: "America/Shiprock", utcOffset: "UTC -06:00" },
  { country: "AMERICA", timeZone: "America/Winnipeg", utcOffset: "UTC -05:00" },
  { country: "EUROPE", timeZone: "Europe/Vatican", utcOffset: "UTC +02:00" },
  { country: "ASIA", timeZone: "Asia/Amman", utcOffset: "UTC +03:00" },
  { country: "ETC", timeZone: "Etc/UTC", utcOffset: "UTC +00:00" },
  { country: "SYSTEMV", timeZone: "SystemV/AST4ADT", utcOffset: "UTC -03:00" },
  { country: "ASIA", timeZone: "Asia/Tokyo", utcOffset: "UTC +09:00" },
  { country: "AMERICA", timeZone: "America/Toronto", utcOffset: "UTC -04:00" },
  { country: "ASIA", timeZone: "Asia/Singapore", utcOffset: "UTC +08:00" },
  {
    country: "AUSTRALIA",
    timeZone: "Australia/Lindeman",
    utcOffset: "UTC +10:00",
  },
  {
    country: "AMERICA",
    timeZone: "America/Los_Angeles",
    utcOffset: "UTC -07:00",
  },
  { country: "SYSTEMV", timeZone: "SystemV/EST5EDT", utcOffset: "UTC -04:00" },
  { country: "PACIFIC", timeZone: "Pacific/Majuro", utcOffset: "UTC +12:00" },
  {
    country: "AMERICA",
    timeZone: "America/Argentina/Buenos_Aires",
    utcOffset: "UTC -03:00",
  },
  { country: "EUROPE", timeZone: "Europe/Nicosia", utcOffset: "UTC +03:00" },
  {
    country: "PACIFIC",
    timeZone: "Pacific/Guadalcanal",
    utcOffset: "UTC +11:00",
  },
  { country: "EUROPE", timeZone: "Europe/Athens", utcOffset: "UTC +03:00" },
  { country: "United States", timeZone: "US/Pacific", utcOffset: "UTC -07:00" },
  { country: "EUROPE", timeZone: "Europe/Monaco", utcOffset: "UTC +02:00" },
];

export const moduleList = [
  { key: "dashboard", name: "Dashboard", icon: Dashboard },
  { key: "attendance", name: "Attendance", icon: Attendance },
  { key: "livestream", name: "Livestream", icon: Livestream },
  { key: "field", name: "Field", icon: Fields },
  { key: "activity", name: "Activity", icon: Activity },
  { key: "timeline", name: "Timeline", icon: Timeline },
  { key: "productivity", name: "Productivity", icon: Productivity },
  { key: "screenshot", name: "Screenshot", icon: Screenshot },
  { key: "wellness360", name: "Wellness", icon: Wellness },
  { key: "application", name: "Application", icon: Application },
  { key: "alerts", name: "Alerts", icon: Alerts },
  { key: "devices", name: "Devices", icon: Device360 },
  { key: "goals", name: "Goals", icon: GoalsIcon },

  {
    key: "reports",
    name: "Daily Attendance",
    id: "dailyattrendance",
    description: "Reports > Daily Attendance",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Monthly Attendance",
    id: "monthlyattrendance",
    description: "Reports > Monthly Attendance",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Monthly In-Out",
    id: "monthlyinout",
    description: "Reports > Monthly In-Out",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Apps/URLs report",
    id: "applicationreport",
    description: "Reports > Apps/URLs report",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Productivity Report",
    id: "productivity",
    description: "Report > Productivity Report",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Activity Report",
    id: "activityreport",
    description: "Reports > Activity Report",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Break Report",
    id: "breakreport",
    description: "Report > Break Report",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Teams Insight",
    id: "departmentinsight",
    description: "Report > Teams Insight",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Alert Report",
    id: "alertLogs",
    description: "Report > Alert Report",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Devices Report",
    id: "devicereport",
    description: "Report > Devices Report",
    icon: Reports,
  },
  {
    key: "reports",
    name: "Dynamic Report",
    id: "dynamicreport",
    description: "Report > Dynamic Report",
    icon: Reports,
  },

  { key: "projects", name: "Project", icon: Project },
  { key: "notebook", name: "Notebook", icon: Note },
  { key: "user-detail", name: "User details", icon: Users },
  { key: "download", name: "Download", icon: Download },
  { key: "account", name: "Account", icon: Settings },
  { key: "billing", name: "Billing", icon: Billing },
  { key: "leaves", name: "Leave", icon: Settings },

  {
    key: "settings",
    name: "Users & Designations",
    id: 0,
    description: "Setting > Users & Designations",
    icon: UserDesignationIcon,
  },
  {
    key: "settings",
    name: "Teams",
    id: 1,
    description: "Setting > Teams",
    icon: TeamsIcon,
  },
  {
    key: "settings",
    name: "Workplace",
    id: 3,
    description: "Setting > Workplace",
    icon: WellnessIcon,
  },
  {
    key: "settings",
    name: "Shifts",
    id: 4,
    description: "Setting > Shifts",
    icon: ShiftIcon,
  },
  {
    key: "settings",
    name: "Breaks",
    id: 5,
    description: "Setting > Breaks",
    icon: BreaksIcon,
  },
  {
    key: "settings",
    name: "Productivity Rules",
    id: 6,
    description: "Setting > Productivity Rules",
    icon: ProductivityRulesIcon,
  },
  {
    key: "settings",
    name: "Alert Rules",
    id: 7,
    description: "Setting > Alert Rules",
    icon: AlertRulesIcon,
  },
  {
    key: "settings",
    name: "Email Reports",
    id: 8,
    description: "Setting > Email Reports",
    icon: EmailReportsIcon,
  },

  {
    key: "settings",
    name: "Leave Type",
    id: 9,
    description: "Setting > Leave Type",
    icon: Settings,
  },
  {
    key: "settings",
    name: "Goals",
    id: 10,
    description: "Setting > Goals",
    icon: GoalsIcon,
  },
  {
    key: "helps",
    name: "Documentation",
    id: "https://we360.zohodesk.in/portal/en/home",
    description: "Help > Documentation",
    icon: Documentation,
  },
  {
    key: "helps",
    name: "Request Onboarding",
    id: requestOnboardingURL,
    description: "Help > Request Onboarding",
    icon: RequestOnboarding,
  },
  {
    key: "helps",
    name: "Raise a ticket",
    id: raiseTicketURL,
    description: "Help > Raise a ticket",
    icon: RaiseTicet,
  },
];



/////===============WeekPicker===================////////////////

export const getWeekRangePresets = () => {
  const startOfCurrentWeek = dayjs().startOf("week");
  const endOfCurrentWeek = dayjs().endOf("week");
  const startOfPreviousWeek = startOfCurrentWeek.subtract(1, "week");
  const endOfPreviousWeek = endOfCurrentWeek.subtract(1, "week");
  const startOfNextWeek = startOfCurrentWeek.add(1, "week");
  const endOfNextWeek = endOfCurrentWeek.add(1, "week");

  return [
    {
      label: "Previous Week",
      value: [startOfPreviousWeek, endOfPreviousWeek],
    },
    {
      label: "Current Week",
      value: [startOfCurrentWeek, endOfCurrentWeek],
    },
    {
      label: "Next Week",
      value: [startOfNextWeek, endOfNextWeek],
    },
  ];
};
export const disabledDateWeek = (current) => {
  const rangePresets = getWeekRangePresets();

  const startOfCurrentWeek = rangePresets[1].value[0];
  const endOfCurrentWeek = rangePresets[1].value[1];
  const startOfPreviousWeek = rangePresets[0].value[0];
  const endOfPreviousWeek = rangePresets[0].value[1];
  const startOfNextWeek = rangePresets[2].value[0];
  const endOfNextWeek = rangePresets[2].value[1];

  const isInCurrentWeek = current.isBetween(
    startOfCurrentWeek,
    endOfCurrentWeek,
    "day",
    "[]"
  );
  const isInPreviousWeek = current.isBetween(
    startOfPreviousWeek,
    endOfPreviousWeek,
    "day",
    "[]"
  );
  const isInNextWeek = current.isBetween(
    startOfNextWeek,
    endOfNextWeek,
    "day",
    "[]"
  );

  return !(isInCurrentWeek || isInPreviousWeek || isInNextWeek);
};
