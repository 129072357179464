import { put } from "redux-saga/effects";
import { BillingAction, TenantAction, UserAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";
import { fetchSubscriptions } from "../billing/action";
import { ExportCSV } from "utils/export-csv";
import dayjs from "dayjs";
import { fetchMeInfo } from "./action";

const mergeArrays = (userlists = [], rolelists = []) =>
  userlists.map((obj) => {
    const index = rolelists.findIndex(
      (el) => el.identity.id === obj.identity.id
    );

    const { role, id } = index !== -1 ? rolelists[index] : {};
    //
    const data = {
      role_name: (role && role.name) || "",
      role_id: (role && role.id) || "",
      roleAssignmentId: id,
    };

    return {
      ...obj,
      data,
      key: obj.identity.id,
    };
  });

const ExportData = (fileName, data, colunms, userType) => {
  let result = [];
  const generatedDate =
    "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");

  const generatedData = [
    fileName,
    generatedDate,
    `Filter :- ${userType} user list`,
    colunms.join(),
  ];

  data?.forEach((record) => {
    const addColunmsValues = [
      !record?.identity?.first_name && !record?.identity?.last_name
        ? "--"
        : record?.identity?.first_name + " " + record?.identity?.last_name,
      record?.identity?.email,
      record?.data?.role_name,
      record?.attached_group?.name,
      record?.gender,
      record?.designation?.name,
      record?.date_of_birth,
      record?.date_of_joining,
      record?.phone_number,
      record?.identity?.is_active === true ? "Active" : "Inactive",
    ];
    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const userStatus =
    payload.userType === true
      ? "Active"
      : payload.userType === false
      ? "Deactivated"
      : "All";
  const csvData = data;
  const colunms = [
    "User Name",
    "Email",
    "Role",
    "Team",
    "Gender",
    "Designation",
    "DOB",
    "DOJ",
    "Phone number",
    "Status",
  ];
  const exdata = ExportData("User List", csvData, colunms, userStatus);
  ExportCSV("User_list", exdata);
};

export function* CreateUser(data) {
  const config = {
    method: "POST",
    url: `api/v1/identities/advanced/?skip_email=${data.data.skipEmail}`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(
      UserAction.UserCreateSuccess({
        ...data.data.Bodydata,
        identity: response.data,
      })
    );
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserAction.UserCreateFailed(errorMessage));
  }
}
export function* CreateInviteUser(data) {
  const config = {
    method: "POST",
    url: "USER_PHASE_3",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.UserInviteCreateSuccess(response.data));
    } else {
      yield put(UserAction.UserInviteCreateFailed(response));
    }
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserAction.UserInviteCreateFailed(errorMessage));
  }
}
export function* UpdateUser(data) {
  const config = {
    method: "PUT",
    url: "api/v1/identities/" + data.data.id,

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.UserUpdateSuccess(response.data));
    } else {
      yield put(UserAction.UserUpdateFailed(response));
    }
  } catch (err) {
    yield put(UserAction.UserUpdateFailed(err));
  }
}

export function* UserList(data) {
  const config = {
    method: "GET",
    url: data.data.isOwner
      ? "api/v1/user_infos/?page=1&size=10000&sortBy=id&direction=asc"
      : "api/v1/me/hierarchy/user-infos/?page=1&size=10000&sortBy=id&direction=asc",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.UserListSuccess(response.data));
    } else {
      yield put(UserAction.UserListFailed(response));
    }
  } catch (err) {
    yield put(UserAction.UserListFailed(err));
  }
}

export function* FieldUserList(data) {
  const config = {
    method: "POST",
    url: `api/v1/user_infos/search?page=${data.data.bodydata.page}&size=${data.data.bodydata.size}&sortBy=id&direction=asc`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.data.headers,
    },
    fetchPolicy: "no-cache",
    data: data.data.bodydata,
  };
  try {
    var response = yield Client.request(config);
    yield put(UserAction.FieldUserListSuccess(response.data));
  } catch (err) {
    yield put(UserAction.FieldUserListFailed(err));
  }
}

export function* TeamWiseUserList(data) {
  const config = {
    method: "POST",
    url: "query/user/users_via_group",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.TeamWiseUserListSuccess(response.data.data));
    } else {
      yield put(UserAction.TeamWiseUserListFailed(response));
    }
  } catch (err) {
    yield put(UserAction.TeamWiseUserListFailed(err));
  }
}

export function* RoleUserList(data) {
  const config = {
    method: "GET",
    url: `api/v1/role_assignments/?size=9999`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (response.status === 200) {
      yield put(UserAction.RoleUserListSuccess(response.data));
    } else {
      yield put(UserAction.RoleUserListFailed(response));
    }
  } catch (err) {
    yield put(UserAction.RoleUserListFailed(err));
  }
}

export function* UpdateRoleUser(data) {
  const deleteConfig = {
    method: "DELETE",
    url: "api/v1/role_assignments/" + data.data.id,

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  const config = {
    method: "POST",
    url: "api/v1/role_assignments/",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    if (data.data.id) {
      yield Client.request(deleteConfig);
    }
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.RoleUserListSuccess(response.data));
    } else {
      yield put(UserAction.RoleUserUpdateFailed(response));
    }
  } catch (err) {
    yield put(UserAction.RoleUserUpdateFailed(err));
  }
}
export function* UpdateInfoUser(data) {
  const config = {
    method: "PUT",
    url: "api/v1/user_infos/" + data.data.id,

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(UserAction.UserInfoUpdateSuccess(response.data));
  } catch (err) {
    yield put(UserAction.UserInfoUpdateFailed(err));
  }
}

export function* MeDetail(data) {
  const config = {
    method: "GET",
    url: "ME_DETAIL_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
    withCredentials: true,
  };
  try {
    const response = yield Client.request(config);
    yield put(UserAction.MeDetailSuccess(response.data));
  } catch (err) {
    yield put(UserAction.MeDetailFailed(err));
  }
}

export function* uploadAvatar(data) {
  const config = {
    url: data.data.apiPath,
    method: data.data.uploadMethod,
    headers: {
      ...data.data.headers,
    },
    data: data.data.data,
  };
  try {
    const response = yield Client.request(config);

    yield put(UserAction.SetUploadAvatarSuccess(response.data));
  } catch (err) {
    yield put(UserAction.SetUploadAvatarFailed(SOMETHING_WENT_WRONG));
  }
}

export function* GetUserIdsList(data) {
  const config = {
    method: "POST",
    url: "query/live_stream/user_list",
    fetchPolicy: "no-cache",
    data: data.data,
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.GetUserIdsSuccess(response.data));
    } else {
      yield put(UserAction.GetUserIdsFailed(response));
    }
  } catch (err) {
    yield put(UserAction.GetUserIdsFailed(err));
  }
}

export function* FieldUserById(data) {
  const config = {
    method: "POST",
    url: `query/tracker/device_track`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.data.headers,
    },
    data: data.data.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (response.status === 200) {
      yield put(UserAction.FieldUserByIdSuccess(response.data.data));
    } else {
      yield put(UserAction.FieldUserListFailed(response));
    }
  } catch (err) {
    yield put(UserAction.FieldUserListFailed(err));
  }
}

export function* FieldUserLastLocation(data) {
  const config = {
    method: "POST",
    url: `query/tracker/user_last_location`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.data.headers,
    },
    data: data.data.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (response.status === 200) {
      yield put(UserAction.FieldUserLastLocationSuccess(response.data.data));
    } else {
      yield put(UserAction.FieldUserLastLocationFailed(response));
    }
  } catch (err) {
    yield put(UserAction.FieldUserLastLocationFailed(err));
  }
}

export function* UserBulkImportValidate(data) {
  const config = {
    method: "POST",
    url: `api/v1/batch-jobs/identities-import/validate/`,
    headers: {
      // Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
    },
    data: data.data.identities,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (response.status === 200) {
      yield put(UserAction.UserBulkImportValidateSuccess());
    }
  } catch (err) {
    const errorData = {
      status: err?.response?.status,
      response: err?.response?.data?.errors?.[0],
    };
    yield put(UserAction.UserBulkImportValidateFailed(errorData));
  }
}

export function* UserBulkImport(data) {
  const config = {
    method: "POST",
    url: `/api/v1/batch-jobs/identities-import/`,
    headers: {
      // Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
    },
    data: data.data.identities,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (response.status === 201) {
      yield put(UserAction.UserBulkImportSuccess(response.data));
    }
  } catch (err) {
    const errorData = {
      status: err?.response?.status,
      response: err?.response?.data?.errors?.[0],
    };
    yield put(UserAction.UserBulkImportFailed(errorData));
  }
}

export function* UserBulkImportGetJobStatus(data) {
  const config = {
    method: "GET",
    url: `/api/v1/batch-jobs/${data?.data?.jobId}`,
    headers: {
      // Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
    },
    data: data.data.identities,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (response.status === 200) {
      yield put(UserAction.UserBulkImportGetJobStatusSuccess(response.data));
    }
  } catch (err) {
    const errorData = {
      status: err?.response?.status,
      response: err?.response?.data?.errors?.[0],
    };
    yield put(UserAction.UserBulkImportGetJobStatusFailed(errorData));
  }
}

export function* UserBulkImportlogs(data) {
  const config = {
    method: "GET",
    url: `/api/v1/batch-jobs/?page=1&size=100&sortBy=id&direction=asc`,
    headers: {
      // Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
    },
    data: data.data.identities,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.UserBulkImportLogsSuccess(response.data));
    }
  } catch (err) {
    yield put(UserAction.UserBulkImportLogsFailed(err));
  }
}

export function* userNewList(data) {
  const config = {
    method: "GET",
    url: `api/v1/user_infos/?page=${data.data.page}&size=${data.data.pageSize}&sortBy=id&direction=desc`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
    },
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(UserAction.userNewListSuccess(response.data));
    } else {
      yield put(UserAction.userNewListFailed(response));
    }
  } catch (err) {
    yield put(UserAction.userNewListFailed(err));
  }
}

export function* userSearchList(data) {
  const config = {
    method: "POST",
    url: `api/v1/user_infos/search?page=${data.data.page}&size=${data.data.pageSize}&sortBy=${data.data.sortBy}&direction=${data.data.direction}`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
    },
    fetchPolicy: "no-cache",
    data: data.data.bodyData,
  };
  try {
    const response = yield Client.request(config);

    yield put(UserAction.userSearchListSuccess(response.data));
  } catch (err) {
    yield put(UserAction.userSearchListFailed(err));
  }
}

const getDeactivateUserConfig = (identityId, headers, linkedId) => {
  return {
    method: "POST",
    url: `/api/v1/identities/deactivate/?identityId=${identityId}${
      linkedId ? `&linkedId=${linkedId}` : ""
    }`,
    headers: headers,
  };
};

export function* deactivateUsersSaga(action) {
  try {
    yield Promise.all(
      action.payload.selectedIdentityIDs.map((item) =>
        Client.request(
          getDeactivateUserConfig(
            item.userId,
            action.payload.headers,
            item.linkedId
          )
        )
      )
    );

    notification.success({
      message: "Deactivated users successfully!",
      description: "Users will be logged out within 20 minutes",
    });
    yield put(fetchMeInfo({ headers: action.payload.headers }));
    yield put(
      UserAction.deactivateUsersComplete({
        success: true,
        deactivatedIdentityIDs: action.payload.selectedIdentityIDs.map(
          (item) => item.userId
        ),
      })
    );
  } catch (err) {
    yield put(UserAction.deactivateUsersComplete({ success: false }));
  }
}

const getActivateUserConfig = (identityId, headers) => {
  return {
    method: "POST",
    url: `/api/v1/identities/activate/?identityId=${identityId}`,
    headers: headers,
  };
};

export function* activateUsersSaga(action) {
  try {
    yield Promise.all(
      action.payload.selectedIdentityIDs.map((identityId) =>
        Client.request(
          getActivateUserConfig(identityId, action.payload.headers)
        )
      )
    );

    notification.success({ message: "Activated users successfully!!" });
    yield put(fetchMeInfo({ headers: action.payload.headers }));
    yield put(
      UserAction.activateUsersComplete({
        success: true,
        userList: action.payload.userSearchListData.filter((user) =>
          action.payload.selectedIdentityIDs.includes(user?.identity?.id)
        ),
      })
    );
  } catch (err) {
    yield put(UserAction.activateUsersComplete(false));
  }
}

export function* roleAssignmentsSearch(data, userlist) {
  const config = {
    method: "POST",
    url: `api/v1/role_assignments/search?size=10000`,
    headers: {
      "Content-Type": "application/json",
      ...data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      const res = mergeArrays(userlist, response?.data?.data);
      getDownloadCSV(data, res);
      yield put(UserAction.ExportUserListSuccess(response.data));
    } else {
      yield put(UserAction.ExportUserListFailed(response));
    }
  } catch (err) {
    yield put(UserAction.ExportUserListFailed(err));
  }
}

export function* exportUserList(data) {
  const config = {
    method: "POST",
    url: `api/v1/user_infos/search?page=1&size=10000&sortBy=id&direction=asc`,
    headers: {
      "Content-Type": "application/json",
      ...data.data.token,
    },
    data: data.data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      const userIds = response.data?.data.map((item) => item?.identity?.id);
      const bodyData = {
        contains: {
          "identity.id": userIds,
        },
        hints: {
          "contains.identity.id": "uuid",
        },
      };
      const roleData = {
        token: data.data.token,
        userType: data.data.userType,
        bodyData: bodyData,
      };
      yield roleAssignmentsSearch(roleData, response.data?.data);
    }
  } catch (err) {
    yield put(UserAction.ExportUserListFailed(err));
  }
}

export function* MeDetailRecheckSaga(data) {
  const config = {
    method: "GET",
    url: "ME_DETAIL_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
    withCredentials: true,
  };
  try {
    const response = yield Client.request(config);
    yield put(UserAction.MeDetailRecheckSuccess(response.data));
  } catch (err) {
    yield put(UserAction.MeDetailRecheckFailed(err));
  }
}

export function* fetchMeInfoSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/me/info?cached=${!!action.payload.cached}`,
    headers: {
      ...action.payload.headers,
    },
    fetchPolicy: "no-cache",
    withCredentials: true,
  };
  try {
    const response = yield Client.request(config);

    if (response?.data?.user_info) {
      yield put(UserAction.MeDetailSuccess(response.data.user_info));
      yield put(UserAction.MeDetailRecheckSuccess(response.data.user_info));
    } else {
      yield put(UserAction.MeDetailFailed(SOMETHING_WENT_WRONG));
      yield put(UserAction.MeDetailRecheckFailed(SOMETHING_WENT_WRONG));
    }

    if (response.data.role_assignments) {
      yield put(
        UserAction.fetchMeRoleAssignmentSuccess(response.data.role_assignments)
      );
    } else {
      yield put(UserAction.fetchMeRoleAssignmentFailed(SOMETHING_WENT_WRONG));
    }

    if (response?.data?.license_details) {
      yield put(
        BillingAction.fetchSubscriptionsSuccess({
          ...response.data.license_details,
          activeUsers: response.data?.tenant?.active_users,
        })
      );
    } else {
      yield put(BillingAction.fetchSubscriptionsFailed(SOMETHING_WENT_WRONG));
    }
    if (response?.data?.license_details) {
      yield put(TenantAction.TenantListSuccess(response.data.tenant));
    } else {
      yield put(TenantAction.TenantListFailed(SOMETHING_WENT_WRONG));
    }
  } catch (err) {
    yield put(UserAction.MeDetailFailed(err));
    yield put(UserAction.MeDetailRecheckFailed(err));
    yield put(UserAction.fetchMeRoleAssignmentFailed(err));
    yield put(BillingAction.fetchSubscriptionsFailed(err));
    yield put(TenantAction.TenantListFailed(err));
  }
}

export function* bulkTeamUpdateSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/user_infos/change-group/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(UserAction.bulkUsersTeamUpdateSuccess(response.data));
    notification.success({
      message: `Team update successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserAction.bulkUsersTeamUpdateFailed(errorMessage));
  }
}

export function* changeGroupSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/user_infos/change-group/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    yield Client.request(config);
    yield put(UserAction.changeGroupSuccess(action.payload.data));
    notification.success({
      message: `Team updated successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserAction.changeGroupFailed(errorMessage));
  }
}
