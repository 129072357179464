import * as actionTypes from "./actionTypes";

export const fetchTimesheet = (payload) => {
  return {
    type: actionTypes.FETCH_TIMESHEET,
    payload,
  };
};

export const fetchTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TIMESHEET_SUCCESS,
    payload,
  };
};

export const fetchTimesheetFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TIMESHEET_FAILED,
    payload,
  };
};

export const createTimesheet = (payload) => {
  return {
    type: actionTypes.CREATE_TIMESHEET,
    payload,
  };
};

export const createTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_TIMESHEET_SUCCESS,
    payload,
  };
};

export const createTimesheetFailed = (payload) => {
  return {
    type: actionTypes.CREATE_TIMESHEET_FAILED,
    payload,
  };
};

export const updateTimesheet = (payload) => {
  return {
    type: actionTypes.UPDATE_TIMESHEET,
    payload,
  };
};

export const updateTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_TIMESHEET_SUCCESS,
    payload,
  };
};

export const updateTimesheetFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_TIMESHEET_FAILED,
    payload,
  };
};

export const bulkSubmitTimesheet = (payload) => {
  return {
    type: actionTypes.BULK_SUBMIT_TIMESHEET,
    payload,
  };
};

export const bulkSubmitTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.BULK_SUBMIT_TIMESHEET_SUCCESS,
    payload,
  };
};

export const bulkSubmitTimesheetFailed = (payload) => {
  return {
    type: actionTypes.BULK_SUBMIT_TIMESHEET_FAILED,
    payload,
  };
};

export const setTimesheetErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_TIMESHEET_ERROR_MESSAGE,
    payload,
  };
};
export const deleteTimesheet = (payload) => {
  return {
    type: actionTypes.DELETE_TIMESHEET,
    payload,
  };
};

export const deleteTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_TIMESHEET_SUCCESS,
    payload,
  };
};

export const deleteTimesheetFailed = (payload) => {
  return {
    type: actionTypes.DELETE_TIMESHEET_FAILED,
    payload,
  };
};

export const editTimesheetDetail = (payload) => {
  return {
    type: actionTypes.EDIT_TIMESHEET_DETAIL,
    payload,
  };
};

export const editTimesheetDetailSuccess = (payload) => {
  return {
    type: actionTypes.EDIT_TIMESHEET_DETAIL_SUCCESS,
    payload,
  };
};

export const editTimesheetDetailFailed = (payload) => {
  return {
    type: actionTypes.EDIT_TIMESHEET_DETAIL_FAILED,
    payload,
  };
};
