import _ from "lodash";
import { SIGNUP_INITIATE } from "route/constant";
import dayjs from "dayjs";
function calculatePercentageAndComparison(num1 = 0, num2 = 0) {
  try {
    if (!num1 && !num2) {
      return { percentage: "0.00", greater: "same" };
    }
    if (!num1) {
      return { percentage: 100, greater: false };
    }
    if (!num2) {
      return { percentage: 100, greater: true };
    }
    if (num1 === num2) {
      return { percentage: "0.00", greater: "same" };
    }
    return {
      percentage: Math.abs(((num1 - num2) / num2) * 100).toFixed(2),

      greater: num1 > num2,
    };
  } catch (ex) {
    return {
      percentage: 0,
      greater: false,
    };
  }
}

function extractCategories(data) {
  // Extract the unique categories from the JSON object
  const categories = _.uniq(_.map(data, "group_name"));

  return categories;
}

function extractDuration(data) {
  // Extract the unique categories from the JSON object
  const categories = _.uniq(_.map(data, "online_duration"));

  return categories;
}

const convertHourFormat = (hour, minutes = 0) => {
  const suffix = hour >= 12 ? "pm" : "am";
  let hours = hour > 12 ? hour - 12 : hour;
  hours = hours === 0 ? 12 : hours;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${suffix}`;
};

const redirectToPortal = (path = SIGNUP_INITIATE) => {
  if (process.env.REACT_APP_HOST_TYPE === "CUSTOM") {
    window.open(`${window.location.origin}${path}`, "_self");
    return;
  }

  window.open(
    `${window.location.protocol}//portal.${window.location.host
      .split(".")
      .slice(1)
      .join(".")}${path}`,
    "_self"
  );
};

export const generateRandomPassword = (length) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return Array.from(
    { length: length },
    () => charset[Math.floor(Math.random() * charset.length)]
  ).join("");
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

// Function to calculate duration
export const calculateStartEndTimeDuration = (start, end) => {
  const diff = dayjs.duration(dayjs(end, "HH:mm").diff(dayjs(start, "HH:mm")));
  const hours = diff.hours().toString().padStart(2, "0");
  const minutes = diff.minutes().toString().padStart(2, "0");
  return `${hours}h ${minutes}m`;
};

export const formatTimeRange = (startTime, endTime) => {
  return `${startTime} → ${endTime}`;
};
const generateDateRange = (start, end) => {
  const numberOfDays = end.diff(start, "day") + 1;

  return Array.from({ length: numberOfDays }, (_, index) =>
    start.add(index, "day").format("DD MMMM, dddd")
  );
};
export {
  calculatePercentageAndComparison,
  extractCategories,
  extractDuration,
  convertHourFormat,
  redirectToPortal,
  generateDateRange,
};
