import { put } from "redux-saga/effects";
import { TimesheetAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchTimesheetSaga(action) {
  // const queryString = Object.entries(action.payload.body)
  //   .map(([key, value]) => {
  //     if (typeof value === "object") {
  //       return Object.entries(value)
  //         .map(
  //           ([subKey, subValue]) =>
  //             `${key}[${subKey}]=${encodeURIComponent(subValue)}`
  //         )
  //         .join("&");
  //     } else {
  //       return `${key}=${encodeURIComponent(value)}`;
  //     }
  //   })
  //   .join("&");
  const config = {
    method: "POST",
    url: action.payload.isOwner
      ? `/api/v1/me/hierarchy/time_entries/search/?${action.payload.query}` //`/api/v1/me/time_entries/search/`
      : //  `/api/v1/me/hierarchy/time_entries/search/?${action.payload.query}`
        `/api/v1/me/time_entries/search/?${action.payload.query}`,
    // url:`/api/v1/me/hierarchy/ti me_entries/search/?${action.payload.query}`,

    headers: {
      ...action.payload.header,
    },
    data: action.payload.bodyData,

    // data: !action.payload.isOwner ? action.payload.body : {},
  };
  try {
    const response = yield Client.request(config);
    console.log("response timesheet get", response);
    yield put(TimesheetAction.fetchTimesheetSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.fetchTimesheetFailed(errorMessage));
  }
}

export function* createTimesheetSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_entries/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.createTimesheetSuccess(response.data));
    notification.success({
      message: `Successfully Created TImehseet!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.createTimesheetFailed(errorMessage));
  }
}

export function* updateTimesheetSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/hierarchy/time_entries/bulk-approve/`,

    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.updateTimesheetSuccess(response.data));
    notification.success({
      message: `Weekly Timesheet Update Successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.updateTimesheetFailed(errorMessage));
  }
}

export function* deleteTimesheetSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/me/time_entries/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(TimesheetAction.deleteTimesheetSuccess());
    notification.success({
      message: `Successfully Deleted Timesheet!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.deleteTimesheetFailed(errorMessage));
  }
}

export function* bulkSubmitTimesheetSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_entries/bulk-submit/`,

    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.bulkSubmitTimesheetSuccess(response.data));
    notification.success({
      message: `Your timesheet was submitted successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.bulkSubmitTimesheetFailed(errorMessage));
  }
}

export function* editTimesheetDetailSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/me/time_entries/${action.payload.id}`,

    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.editTimesheetDetailSuccess(response.data));
    notification.success({
      message: `Timesheet Update Successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.editTimesheetDetailFailed(errorMessage));
  }
}
