import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  livestreamLoading: true,
  screenshotLoading: true,
  pagination: initialPagination,
  userDataLoading: true,
  userData: {},
  errorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.START_LIVESTREAM:
      return {
        ...state,
        livestreamLoading: true,
        errorMessage: "",
      };

    case actionTypes.START_LIVESTREAM_SUCCESS:
      return {
        ...state,
        livestreamLoading: false,
      };

    case actionTypes.START_LIVESTREAM_FAILED:
      return {
        ...state,
        livestreamLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.FETCH_USER_DATA:
      return {
        ...state,
        userDataLoading: true,
        errorMessage: "",
      };

    case actionTypes.FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userDataLoading: false,
        userData: (action.payload.data || []).reduce(
          (acc, latestPing) => ({
            ...acc,
            [latestPing.identityId]: latestPing,
          }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_USER_DATA_FAILED:
      return {
        ...state,
        userDataLoading: false,
        userData: {},
        pagination: initialPagination,
        errorMessage: action.payload,
      };

    case actionTypes.START_SCREENSHOT:
      return {
        ...state,
        screenshotLoading: true,
        errorMessage: "",
      };

    case actionTypes.START_SCREENSHOT_SUCCESS:
      return {
        ...state,
        screenshotLoading: false,
      };

    case actionTypes.START_SCREENSHOT_FAILED:
      return {
        ...state,
        screenshotLoading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
